.f-group {
  margin-bottom: 15px;
}

.f-group label {
  margin: 10px 0;
  word-break: break-word;
  font-size: 13px;
  font-weight: 500;
}

.f-control input[type='checkbox'] + label {
  display: inline;
}

.f-row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.f-control,
.f-group .form-control {
  display: block;
  width: 100%;
  min-height: 39px;
  padding: 8px 12px;
  font-size: 15px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}

.f-control,
.f-group .Select-control,
.f-group .form-control {
  border-radius: 3px;
}

.f-inline-dialog {
  display: flex;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  align-items: center;
}

.f-control[disabled],
.f-control[readonly],
.f-group .form-control[readonly],
.f-group .form-control[disabled],
.f-group .form-control.readonly,
.f-group .form-control.disabled,
.f-group .f-control.readonly,
.f-group .f-control.disabled,
.disabled-signature .ql-disabled,
fieldset[disabled] .f-control {
  color: #aaa;
  background-color: #f9f9f9;
  cursor: not-allowed;
  outline: 0 !important;
  box-shadow: none;
}

.disabled-signature .ql-disabled {
  border-radius: 3px;
  padding: 12px 15px;
}

.f-group input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-weight: normal;
}

.f-group .dob-picker {
  width: 100%;
}

.dob-picker .form-control[readonly],
.dob-picker .f-control[readonly] {
  color: #555;
  background-color: #fff;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.f-group.f-row > label {
  margin-right: 10px;
  width: 190px;
}

.f-group.f-row .f-control,
.f-group.f-row .form-control,
.f-group.f-row .Select {
  flex: 1;
}

.f-group .quill {
  display: flex;
  flex-direction: column;
}

.marker {
  position: absolute;
  width: 3px;
  height: 39px;
  background-color: #bb4444;
  border-radius: 3px 0 0 3px;
  z-index: 1;
}

.marker.required {
  background-color: #bb4444;
}

.marker.green {
  background-color: #3b3;
}

.marker.grey {
  background-color: #777;
}

.f-group.dirty .f-control,
.f-group.dirty .form-control,
.f-group.dirty .Select-control,
.f-group.dirty .ql-editor,
.f-group.dirty .f-input-group > .f-btn.link {
  background-color: color(#3b3 tint(94%));
  border-color: color(#3b3 tint(50%) saturation(30%));
}

.f-group.error .f-control,
.f-group.error .form-control,
.f-group.error .Select-control,
.f-group.error .ql-editor,
.f-group.error .f-input-group > .f-btn.link {
  background-color: color(#de1b1b tint(94%));
  border-color: color(#de1b1b tint(50%) saturation(30%));
}

.f-error-message {
  font-size: 13px;
  margin: 5px 0 0 3px;
  color: #b70000;
}

.f-icon {
  position: absolute;
  margin: 12px 0;
  top: 0;
  font-size: 13px;
  color: #999;
  right: -22px;
}

.f-row > [class*='flex-'] {
  align-items: flex-end;
}

.f-row [class*='flex-'] + [class*='flex-'] {
  margin-left: 10px;
}

.f-input-group {
  display: flex;
}

.f-input-group .f-control,
.f-input-group .form-control {
  border-radius: 3px 0 0 3px;
  border-right-color: transparent !important;
}

.f-input-group > .f-btn,
.f-input-group > .f-btn.link {
  margin-bottom: 0;
  width: auto;
  min-width: auto;
  height: 39px;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  outline: 0;
}

.f-input-group > .f-btn.link {
  background-color: #fff;
}

.f-input-group [readonly] + .f-btn.link,
.f-input-group [disabled] + .f-btn.link {
  background-color: #f9f9f9;
}

.f-input-group + .f-group,
.f-input-group + .f-row {
  margin-top: 15px;
}

.f-btn-group,
.f-inline-dialog {
  margin: 0 0 15px 190px;
}

/* FORM BUTTONS */
.f-btn {
  display: inline-block;
  min-width: 108px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 7px 12px;
  font-size: 15px;
  color: #fff;
  background-color: #888;
  border: 2px solid transparent;
  border-radius: 5px;
  user-select: none;
}

.f-btn.outline {
  color: #888;
  border-color: color(#888 a(80%));
  background: transparent;
}

.f-btn.primary {
  background-color: #2985c9;
}

.f-btn.primary[disabled],
.f-btn.primary:hover[disabled] {
  opacity: 0.5;
}

.f-btn.primary.outline {
  color: #2985c9;
  border-color: color(#2985c9 a(80%));
  background: transparent;
}

.f-btn.primary:hover,
.f-btn.primary:active {
  background-color: color(#2985c9 shade(10%));
  color: #fff;
}

.f-btn.secondary {
  background-color: #888;
}

.f-btn.secondary.outline {
  color: #888;
  border-color: color(#888 a(80%));
  background: transparent;
}

.f-btn.secondary:hover,
.f-btn.secondary:active {
  background-color: color(#888 shade(10%));
}

.f-btn.danger {
  background-color: #de1b1b;
}

.f-btn.danger.outline {
  color: #de1b1b;
  border-color: color(#de1b1b a(80%));
  background: transparent;
}

.f-btn.danger:hover,
.f-btn.danger:active {
  background-color: color(#de1b1b shade(10%));
}

.f-btn.warning {
  background-color: #ff9000;
}

.f-btn.warning.outline {
  color: #ff9000;
  border-color: color(#ff9000 a(80%));
  background: transparent;
}

.f-btn.warning:hover,
.f-btn.warning:active {
  background-color: color(#ff9000 shade(10%));
}

.f-btn.success {
  background-color: #3b3;
}

.f-btn.success.outline {
  color: #3b3;
  border-color: color(#3b3 a(80%));
  background: transparent;
}

.f-btn.success:hover,
.f-btn.success:active {
  background-color: color(#3b3 shade(10%));
}

.f-btn.info {
  background-color: #00c2ff;
}

.f-btn.info.outline {
  color: #00c2ff;
  border-color: color(#00c2ff a(80%));
  background: transparent;
}

.f-btn.info:hover,
.f-btn.info:active {
  background-color: color(#00c2ff shade(10%));
}

.f-btn.dark {
  background-color: #555;
}

.f-btn.dark.outline {
  color: #555;
  border-color: color(#555 a(80%));
  background: transparent;
}

.f-btn.dark:hover,
.f-btn.dark:active {
  background-color: color(#555 shade(10%));
}

.f-btn.light,
.f-btn.light.outline {
  color: #555;
  border-color: color(#555 a(80%));
  background: transparent;
}

.f-btn.link {
  color: #2985c9;
  border-color: transparent;
  background: transparent;
  min-width: auto;
}

.f-btn.link:hover,
.f-btn.link:active {
  background: transparent;
  text-decoration: underline;
}

.f-btn:hover,
.f-btn:active {
  background-color: color(#888 shade(10%));
  text-decoration: underline;
}

.f-btn.outline:hover,
.f-btn.outline:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.f-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.f-btn + .f-btn {
  margin-left: 5px;
}

@media (--viewport-sm) {
  .f-row {
    display: block;
  }

  .f-row [class*='flex-'] + [class*='flex-'] {
    margin-left: 0;
  }

  .f-group.f-row > label {
    margin: 0 0 5px 0;
    width: auto;
  }

  .f-inline-dialog {
    display: block;
    text-align: center;
  }

  .f-btn-group .f-btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .f-btn-group .f-btn + .f-btn {
    margin-left: 0;
  }

  .f-btn-group {
    margin: 0 0 15px 0;
  }
}
