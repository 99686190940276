.bsq-table {
  border-collapse: collapse;
  font-size: 13px;
}

.bsq-table,
.bsq-table tbody {
  width: 100%;
}

.bsq-table td,
.bsq-table th {
  text-align: left;
  padding: 8px;
}

.bsq-table td.right,
.bsq-table th.right {
  text-align: right;
}

.bsq-table td.center,
.bsq-table th.center {
  text-align: center;
}

.bsq-table,
.bsq-table * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bsq-table .label {
  display: none;
  font-weight: bolder;
}

.bsq-table.bordered td,
.bsq-table.bordered th {
  border: 1px solid #ddd;
}

.bsq-table.striped tr {
  background-color: #ffffff;
}

.bsq-table.striped tr:nth-child(even) {
  background-color: #f5f7f9;
}

.bsq-table thead tr:nth-child(odd),
.bsq-table thead tr:nth-child(even) {
  background-color: #f5f7f9;
}

.bsq-table.striped thead tr {
  border-top: 1px solid #ddd;
}

.bsq-table.striped td,
.bsq-table.striped th {
  border-bottom: 1px solid #ddd;
}

.bsq-table thead.dark th {
  background-color: #8e98a3;
  color: #fff;
  border: 1px solid transparent;
  font-weight: normal;
}

@media (max-width: 768px) {
  .bsq-table,
  .bsq-table tbody,
  .bsq-table thead,
  .bsq-table td,
  .bsq-table th {
    display: block;
    width: 100%;
    float: left;
    border: none;
  }
  .bsq-table thead,
  .bsq-table thead tr,
  .bsq-table thead th {
    height: 0px;
    width: 0px;
    margin: 0px;
    padding: 0px;
    border: 0px;
    visibility: hidden;
    overflow: hidden;
  }
  .bsq-table tr {
    position: relative;
    display: block;
    padding: 8px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    *zoom: 1;

    /* Clearfix */
  }

  /* Clearfix Start */
  .bsq-table tr:before,
  .bsq-table tr:after {
    content: ' ';
    display: table;
  }

  .bsq-table tr:after {
    clear: both;
  }

  /* Clearfix End*/

  .bsq-table.bordered tr {
    border: 1px solid #ddd;
    margin-bottom: -1px;
  }
  .bsq-table .label {
    display: inline;
    font-size: 13px;
    color: black;
  }
  .bsq-table.bordered td,
  .bsq-table.bordered th {
    border: none;
  }
  .bsq-table.striped td,
  .bsq-table.striped th {
    border: none;
  }
  .bsq-table td.center,
  .bsq-table th.center,
  .bsq-table td.right,
  .bsq-table th.right {
    text-align: left;
  }
}
